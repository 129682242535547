/*
 * @Author: zt zhoutao@ydmob.com
 * @Date: 2024-01-15 15:55:42
 * @LastEditors: zt zhoutao@ydmob.com
 * @LastEditTime: 2024-01-15 16:06:51
 * @FilePath: /mediatom-web/src/views/mediadashboard/components/siderCharts/PlatPie/options.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export default {
  tooltip: {
    trigger: 'item',
    transitionDuration: 0,
    appendToBody: true
  },
  legend: {
    show: false
  },
  series: [
    {
      type: 'pie',
      radius: ['0%', '80%'],
      center: ['50%', '50%'],
      roseType: 'area',
      itemStyle: {},
      labelLine: {
        show: true,
        length: 1,
        length2: 1
      },
      data: [
        {
          value: 300,
          name: '',
          itemStyle: {
            color: '#5470C6'
          }
        }
      ]
    }
  ]
}
